
import { defineComponent, ref, computed, onMounted } from "vue";
import { StepperComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

import { getIllustrationsPath } from "@/core/helpers/assets";

interface Step1 {
  appName: string;
  category: string;
}

interface Step2 {
  framework: string;
}

interface Step3 {
  dbName: string;
  dbType: string;
}

interface Step4 {
  nameOnCard: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  cardCvv: string;
  saveCard: string;
}

interface KTCreateApp extends Step1, Step2, Step3, Step4 {}

export default defineComponent({
  name: "create-app-modal",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const currentStepIndex = ref(0);
    const createAppRef = ref<HTMLElement | null>(null);
    const stepperObj = ref<StepperComponent | null>(null);

    onMounted(() => {
      createAppRef.value = document.getElementById(
        "kt_modal_create_app_stepper"
      );
      stepperObj.value = StepperComponent.createInsance(
        createAppRef.value as HTMLElement
      );
    });

    const formData = ref<KTCreateApp>({
      appName: "",
      category: "1",
      framework: "1",
      dbName: "",
      dbType: "1",
      nameOnCard: "Max Doe",
      cardNumber: "4111 1111 1111 1111",
      cardExpiryMonth: "1",
      cardExpiryYear: "2",
      cardCvv: "123",
      saveCard: "1",
    });

    const formInitData = ref<KTCreateApp>({
      appName: "",
      category: "",
      framework: "",
      dbName: "",
      dbType: "",
      nameOnCard: "",
      cardNumber: "",
      cardExpiryMonth: "",
      cardExpiryYear: "",
      cardCvv: "",
      saveCard: "",
    });

    const createAppSchema = [
      Yup.object({
        appName: Yup.string().required().label("App name"),
        category: Yup.string().required().label("Category"),
      }),
      Yup.object({
        framework: Yup.string().required().label("Framework"),
      }),
      Yup.object({
        dbName: Yup.string().required().label("Database name"),
        dbType: Yup.string().required().label("Database engine"),
      }),
      Yup.object({
        nameOnCard: Yup.string().required().label("Name"),
        cardNumber: Yup.string().required().label("Card Number"),
        cardExpiryMonth: Yup.string().required().label("Expiration Month"),
        cardExpiryYear: Yup.string().required().label("Expiration Year"),
        cardCvv: Yup.string().required().label("CVV"),
      }),
    ];

    // extracts the individual step schema
    const currentSchema = computed(() => {
      return createAppSchema[currentStepIndex.value];
    });

    const totalSteps = computed(() => {
      if (!stepperObj.value) {
        return;
      }

      return stepperObj.value.totatStepsNumber;
    });

    const { resetForm, handleSubmit } = useForm<Step1 | Step2 | Step3 | Step4>({
      validationSchema: currentSchema,
    });

    const previousStep = () => {
      currentStepIndex.value--;

      if (!stepperObj.value) {
        return;
      }

      stepperObj.value.goPrev();
    };

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (!stepperObj.value) {
        return;
      }

      stepperObj.value.goNext();
    });

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-semobold btn-light-primary",
        },
      }).then(() => {
        if (!stepperObj.value) {
          return;
        }
        stepperObj.value.goFirst();
        currentStepIndex.value = 0;
        resetForm({
          values: {
            ...formInitData.value,
          },
        });
      });
    };

    return {
      handleStep,
      formSubmit,
      previousStep,
      currentStepIndex,
      totalSteps,
      getIllustrationsPath,
    };
  },
});
