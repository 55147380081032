const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        sectionTitle: "Listing Generator",
        svgIcon: "/media/icons/duotune/general/gen005.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Playground",
            route: "/advert/playground",
          },
          {
            heading: "V1",
            route: "/advert/v1",
          },
          {
            heading: "V2",
            route: "/advert/v2",
          },
          {
            heading: "V3",
            route: "/advert/v3",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
