
import { defineComponent } from "vue";
import CreateAppModalForm from "@/components/modals/wizards/create-app-modal/CreateAppModalForm.vue";

export default defineComponent({
  name: "create-app-modal",
  components: {
    CreateAppModalForm,
  },
});
