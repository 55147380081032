
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "kt-theme-switcher",
  component: {},
  setup() {
    const store = useStore();
    const route = useRoute();

    const themeMode = computed(() => {
      return store.getters.getThemeMode;
    });

    const path = computed(() => route.path);

    const setMode = (mode: string) => {
      store.dispatch(Actions.SET_THEME_MODE_ACTION, mode);
    };

    return {
      themeMode,
      setMode,
      path,
    };
  },
});
