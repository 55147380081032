import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_3 = {
  href: "#",
  class: "btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px",
  "data-kt-menu-trigger": "{default:'click', lg: 'hover'}",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_4 = {
  key: 0,
  class: "svg-icon theme-dark-hide svg-icon-2"
}
const _hoisted_5 = {
  key: 1,
  class: "svg-icon theme-light-hide svg-icon-2"
}
const _hoisted_6 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_7 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  class: "d-flex align-items-center d-lg-none ms-2 me-n3",
  title: "Show header menu"
}
const _hoisted_10 = {
  class: "btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px",
  id: "kt_header_menu_mobile_toggle"
}
const _hoisted_11 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTThemeModeSwitcher = _resolveComponent("KTThemeModeSwitcher")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        (_ctx.themeMode === 'light')
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen060.svg" })
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen061.svg" })
            ]))
      ]),
      _createVNode(_component_KTThemeModeSwitcher)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          src: _ctx.user.picture ? _ctx.user.picture : '/media/avatars/blank.png',
          alt: ""
        }, null, 8, _hoisted_8)
      ]),
      _createVNode(_component_KTUserMenu, { user: _ctx.user }, null, 8, ["user"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, [
          _createVNode(_component_inline_svg, { src: "/media/icons/duotune/text/txt001.svg" })
        ])
      ])
    ])
  ]))
}